._container_1fg2t_1 {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  color: #100d04;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
._title_1fg2t_14 {
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
}
._title_1fg2t_14 > *:first-child {
  margin-right: auto;
}
@media (min-width: 1000px) {
  ._title_1fg2t_14 > *:first-child {
    margin-left: auto;
  }
}
._header_1fg2t_27 {
  margin-bottom: 12px;
}
._edit_1fg2t_30 {
  cursor: pointer;
  margin-left: 4px;
}
._dialogHeader_1fg2t_34 {
  z-index: 1;
  color: #fff;
  background-color: #000;
}
._dialog_1fg2t_34 {
  background-color: #000;
}

._cluster_86pnh_1 {
  display: flex;
  flex-direction: row;
}
@media (min-width: 1000px) {
  ._cluster_86pnh_1 {
    width: calc(40% + 50px);
    margin-left: calc(50% - 50px);
  }
  ._cluster_86pnh_1:nth-child(2n+1) {
    margin-left: 10%;
    flex-direction: row-reverse;
  }
  ._cluster_86pnh_1:nth-child(2n+1) ._imageGrid_86pnh_14 {
    align-items: flex-end;
    justify-content: end;
  }
  ._cluster_86pnh_1:nth-child(2n+1) ._textNode_86pnh_18,
  ._cluster_86pnh_1:nth-child(2n+1) ._titleSection_86pnh_19 {
    text-align: right;
  }
  ._cluster_86pnh_1:nth-child(2n+1) ._dateLine_86pnh_22 ._wrapper_86pnh_22:after {
    right: unset;
    left: -11px;
  }
  ._cluster_86pnh_1:nth-child(2n+1) ._content_86pnh_26 {
    align-items: flex-end;
  }
  ._cluster_86pnh_1:nth-child(2n+1) ._inputWrapper_86pnh_29 {
    flex-direction: row-reverse;
  }
}
._thumbnail_86pnh_33 {
  height: 50px;
  width: 50px;
  padding: 2px;
  margin: 4px;
}
._thumbnail_86pnh_33 > div {
  max-width: 46px;
  max-height: 46px;
  padding: 2px;
  flex-shrink: 0;
  cursor: pointer;
}
._thumbnail_86pnh_33 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
._dateLine_86pnh_22 {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100px;
  flex-shrink: 0;
}
._dateLine_86pnh_22 ._wrapper_86pnh_22 {
  background: #fff;
  padding: 0 10px;
  font-size: 0.9em;
  color: #666;
  position: relative;
  width: 75px;
  text-align: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
._dateLine_86pnh_22 ._wrapper_86pnh_22:after {
  content: "";
  height: 1px;
  width: 18px;
  background: #ddd;
  flex-shrink: 0;
  position: absolute;
  top: 8px;
  right: -11px;
}
._separator_86pnh_82 {
  line-height: 5px;
}
._dateLine_86pnh_22::before,
._dateLine_86pnh_22::after {
  content: '';
  width: 1px;
  height: 100%;
  background: #ccc;
  flex-shrink: 1;
}
._dateLine_86pnh_22::before {
  height: 25px;
  flex-shrink: 0;
}
._dateLine_86pnh_22._hasHeader_86pnh_97::before {
  height: 45px;
  flex-shrink: 0;
}
._imageGrid_86pnh_14 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
._textNode_86pnh_18 {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  font-size: 12px;
}
._content_86pnh_26 {
  margin-top: 20px;
  min-width: calc(100% - 100px);
  display: flex;
  flex-direction: column;
}
._iconButton_86pnh_118._iconButton_86pnh_118 {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
._iconButton_86pnh_118._headlineIcon_86pnh_126 {
  width: 100px;
  justify-content: space-around;
}
._title_86pnh_19 ._iconButton_86pnh_118 {
  margin-left: auto;
}
._imageGrid_86pnh_14 ._iconButton_86pnh_118 {
  height: 62px;
  margin-left: -4px;
  margin-right: -4px;
}
._inputWrapper_86pnh_29 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 19px;
}
._inputWrapper_86pnh_29 select {
  height: 100%;
  margin: 0 4px;
}
._input_86pnh_29 {
  flex-grow: 1;
  height: 100%;
  box-sizing: border-box;
}
._titleSection_86pnh_19 {
  width: 100%;
}

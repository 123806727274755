._toolbar_13jg2_1 {
  opacity: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 7px;
  overflow: hidden;
  height: 0px;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  top: 0;
  width: 100%;
  transition: 0.3s linear;
  box-sizing: border-box;
}
._card_13jg2_17:hover ._toolbar_13jg2_1 {
  height: 30px;
  opacity: 1;
}
._toolbar_13jg2_1 .fa {
  margin-left: 5px;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
._toolbar_13jg2_1 .fa:hover {
  background: rgba(0, 0, 0, 0.2);
}
._card_13jg2_17 {
  position: relative;
}

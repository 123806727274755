._body_jvnom_1 {
  padding: 10px;
}
._head_jvnom_4 {
  margin-bottom: 10px;
}
._url_jvnom_7 {
  text-decoration: underline;
  font-size: 0.8em;
  text-overflow: ellipsis;
  max-width: calc(95vw - 225px);
  overflow: hidden;
  display: block;
}
._urlCell_jvnom_15 {
  position: relative;
}
._urlCell_jvnom_15:hover ._copy_jvnom_18 {
  display: flex;
}
._copy_jvnom_18 {
  position: absolute;
  left: 0;
  height: 14px;
  padding: 2px 10px;
  display: none;
  background: var(--main);
  color: white;
  bottom: -3px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 0.8em;
  cursor: pointer;
  font-weight: 600;
}
._copy_jvnom_18:hover {
  display: flex;
}
._copy_jvnom_18:active {
  background: darkorange;
}
._newLink_jvnom_42 {
  font-size: 0.9;
  font-style: italic;
}
._buttons_jvnom_46 {
  font-size: 1.5em !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 28px;
  background: #eee;
  border-radius: 3px;
  cursor: pointer;
}
._buttons_jvnom_46:hover {
  background: #e5e5e5;
}
._buttons_jvnom_46:active {
  background: #c5c5c5;
}
._access_jvnom_63 {
  margin-top: 2px;
}
._table_jvnom_66 td {
  padding: 0 5px 8px 0px;
}

._dialogBackground_lbjzb_1 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}
._dialog_lbjzb_1 {
  flex-basis: 400px;
  max-width: 95%;
  background: #fff;
  border-radius: 3px;
}
._header_lbjzb_19 {
  background: var(--main);
  height: 25px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
._closeButton_lbjzb_29._closeButton_lbjzb_29 {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 25px;
  align-items: center;
  cursor: pointer;
}
._closeButton_lbjzb_29:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

._uploading_h8g1i_1 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  position: absolute;
  color: white;
}
._card_h8g1i_13 {
  position: relative;
}

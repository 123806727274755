._label_1swgc_1 {
  white-space: nowrap;
  font-size: 0.8em;
}
._required_1swgc_5 {
  color: red;
  font-size: 0.7em !important;
  position: relative;
  top: -3px;
}
._invalid_1swgc_11 {
  border: solid 2px red;
}
._fullWidth_1swgc_14 {
  width: 100%;
}
._wrapper_1swgc_17 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 10px;
}
._wrapper_1swgc_17 input,
._wrapper_1swgc_17 textarea {
  width: 100%;
  box-sizing: border-box;
}
._wrapper_1swgc_17 textarea {
  height: 100px;
  outline: none;
  resize: none;
}

._slide_na2uy_1 {
  width: 100%;
  flex-shrink: 0;
  transition: width 0.3s linear;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
._old_na2uy_10 {
  width: 0px;
}
@keyframes _slide_na2uy_1 {
  0% {
    width: 100vw;
  }
  100% {
    width: 0;
  }
}
._footer_na2uy_21 {
  position: fixed;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20vw;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
}
._imageWrapper_na2uy_35 {
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}
._detail_na2uy_40 {
  height: 100%;
  display: flex;
  position: relative;
}
._button_na2uy_45 {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 60px;
  border-radius: 3px;
  position: absolute;
  height: 100%;
  color: rgba(255, 255, 255, 0.3);
}
._button_na2uy_45:hover {
  background: rgba(255, 255, 255, 0.1);
}
@media (hover: none) {
  ._button_na2uy_45 {
    display: none;
  }
}
._left_na2uy_64 {
  left: 0;
}
._right_na2uy_67 {
  right: 0;
}
._editBar_na2uy_70 {
  display: flex;
  position: absolute;
  bottom: 0;
  height: 100px;
  width: 100%;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  border-top: #272727 solid 1px;
  align-items: center;
  box-shadow: #717171 5px 0 6px -2px;
  justify-content: space-around;
  padding: 0 calc((100vw - 700px) / 2);
  box-sizing: border-box;
  color: #999;
  opacity: 0;
}
._editBar_na2uy_70:hover {
  opacity: 0.5;
}
@media (hover: none) {
  ._editBar_na2uy_70 {
    opacity: 1 !important;
    font-size: 0.4em;
    height: 50px;
    color: #FFF;
  }
}
._editBar_na2uy_70 span {
  cursor: pointer;
}
._editBar_na2uy_70 span:hover {
  color: #fff;
}

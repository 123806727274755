._uploadWrapper_13g6a_1 {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
._input_13g6a_11 {
  height: 0px;
  width: 0px;
  padding: 100% 0 0 100%;
  opacity: 0;
  position: absolute;
  bottom: 0px;
  cursor: pointer;
}

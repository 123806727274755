._active_1remu_1 {
  position: absolute;
  right: 40px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 1px;
  font-family: sans-serif;
  font-size: 1.4em;
  color: #000;
}
._active_1remu_1._fullscreen_1remu_11 {
  color: white;
}
._active_1remu_1._fullscreen_1remu_11:hover {
  color: white;
}
._active_1remu_1:hover {
  color: #222;
}
._inactive_1remu_20 {
  position: absolute;
  right: 40px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 1px;
  font-family: sans-serif;
  font-size: 1.4em;
  color: #ccccccbb;
}
._inactive_1remu_20:hover {
  color: #bbbbbbbb;
}

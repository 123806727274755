._footer_4ejav_1 {
  width: 100%;
  position: fixed;
  bottom: 0;
  background: var(--main);
  z-index: 10;
  min-height: var(--footer-height);
  text-align: center;
  padding: 5px 40px 5px 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-top: solid 1px var(--main-dark);
  font-weight: 700;
}
._closeIcon_4ejav_18 {
  position: absolute;
  right: 0;
  cursor: pointer;
  height: 100%;
  padding: 10px;
  width: 40px;
  box-sizing: border-box;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

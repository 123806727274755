._pager_100pt_1 {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  white-space: nowrap;
  font-size: 1.2em;
  height: 25px;
  margin: 5px 20px;
}
._pager_100pt_1 ._pagerList_100pt_11 ._page_100pt_1 {
  color: var(--black);
  cursor: pointer;
  font-weight: 600;
  margin: 0 2px;
}
._pager_100pt_1 ._pagerList_100pt_11 ._page_100pt_1:hover {
  color: #06200b;
}
._pager_100pt_1 ._pagerList_100pt_11 ._page_100pt_1._activePage_100pt_20 {
  color: var(--main);
  cursor: default;
}
._pager_100pt_1 ._sizeArea_100pt_24 {
  position: absolute;
  right: 0;
}
._wrapper_100pt_28 {
  width: 100%;
  height: 100%;
}
._bottom_100pt_32 {
  margin-top: auto;
  margin-bottom: 10px;
}
._pagedList_100pt_36 {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;
}
@media (pointer: coarse) {
  ._pager_100pt_1 ._pagerList_100pt_11 span {
    margin: 0 5px;
  }
}

._wrapper_uaihy_1 {
  display: flex;
  justify-content: center;
  overflow: hidden;
  align-items: center;
  padding: 5px;
  color: darkgray;
}
._image_uaihy_9 {
  flex: none;
}
._hidden_uaihy_12 {
  opacity: 0;
  position: absolute;
}
._placeholder_uaihy_16 {
  font-size: 9em !important;
}

body {
  color: var(--black);
  background: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
  margin: 0;
  font-size: 14px;
  font-family: Raleway;
  text-align: justify;
}
p {
  margin: 0;
}
a {
  color: var(--black);
  text-decoration: none;
}
h3,
h4,
h2,
h1,
h5 {
  margin: 0;
}
button {
  background: var(--main);
  color: #fff;
  border: solid 1px var(--main-dark);
  border-radius: 2px;
  cursor: pointer;
  padding: 5px;
  font-family: Raleway;
  font-size: 1em;
  outline: none !important;
}
button:hover {
  background: var(--main-hover);
}
button:active {
  background: var(--main-active);
}

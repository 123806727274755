._busyBackground_t8g0a_1 {
  z-index: 1000;
  background: rgba(90, 90, 90, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
._loadingCircle_t8g0a_10 {
  animation: _loadingFrames_t8g0a_1 linear 3s;
  animation-iteration-count: infinite;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: var(--main);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
._loadingCircleContent_t8g0a_22 {
  height: 30px;
  width: 30px;
  padding: 15px;
  text-align: center;
  background: var(--main-dark);
  border-radius: 50%;
  color: var(--black);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
@keyframes _loadingFrames_t8g0a_1 {
  0% {
    opacity: 1;
    padding: 0px;
  }
  55% {
    opacity: 0.7;
    padding: 5.5px;
  }
  100% {
    opacity: 0;
    padding: 9px;
  }
}

._wrapper_1thsf_1 {
  margin: 10px;
  height: 160px;
}
._card_1thsf_5 {
  width: 150px;
  max-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  background: #eee;
  overflow: hidden;
}
._card_1thsf_5:hover {
  background: #ddd;
}
._name_1thsf_18 {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  white-space: nowrap;
}
._name_1thsf_18 p {
  text-overflow: ellipsis;
  overflow: hidden;
}
._nameHidden_1thsf_31 {
  height: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  white-space: nowrap;
}

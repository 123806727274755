:root {
  --topbar-height: 40px;
  --footer-height: 25px;
  --black: #010602;
  --main: #1bab41;
  --main-hover: #168b36;
  --main-active: #13752d;
  --main-dark: #14742d;
}
._body_3u88a_10 {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  color: #100d04;
  padding-top: var(--topbar-height);
  box-sizing: border-box;
}
@media (pointer: coarse) {
  ._body_3u88a_10 {
    font-size: 1.2em;
  }
}
._body_3u88a_10._fullscreenBody_3u88a_24 {
  padding-top: 0px;
}
._topbar_3u88a_27 {
  top: 0px;
  position: fixed;
  width: 100%;
  background: var(--main);
  z-index: 10;
  height: var(--topbar-height);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: solid 1px var(--main-dark);
}
._buttons_3u88a_40 {
  position: absolute;
  left: 7px;
  font-size: 1.6em;
  color: white;
}
._content_3u88a_46 {
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
._symbol_3u88a_55 {
  margin-right: 7px;
}
._fullscreen_3u88a_24 {
  background: transparent;
  color: white;
  border: none;
}
._fullscreen_3u88a_24 a {
  color: white;
}

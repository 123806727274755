._hidden_3t16d_1 {
  display: none;
}
._input_3t16d_4 {
  height: 0px;
  width: 0px;
  opacity: 1;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
._infoText_3t16d_13 {
  max-width: 70vw;
  text-align: center;
  color: #fff;
}
._wrapper_3t16d_18 {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
}
._dragging_3t16d_25 {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
._dragging_3t16d_25 ._input_3t16d_4 {
  padding: 120% 0 0 120%;
}

._description_1ikyp_1 {
  white-space: pre-line;
  margin-top: 5px;
}
._wrapper_1ikyp_5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  margin: 0 10px 10px;
  padding: 10px 0;
  border-bottom: solid 1px var(--black);
  position: sticky;
  top: 0px;
  z-index: 1;
  background: white;
}
._uploadInfo_1ikyp_19 {
  position: absolute;
  bottom: -50px;
  text-align: center;
  background: #ffff95;
  padding: 5px 20px;
  border-radius: 5px;
}
._galleryControl_1ikyp_27 {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  position: absolute;
  right: 0px;
  top: 10px;
  height: 25px;
  align-items: center;
}
._galleryControl_1ikyp_27 li {
  margin: 0 5px;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}
._header_1ikyp_45 {
  display: flex;
  justify-content: stretch;
  flex-direction: row;
  align-items: center;
  width: 100%;
}
._header_1ikyp_45 div {
  flex-basis: 210px;
  flex-shrink: 0;
}
._header_1ikyp_45 div:first-child {
  flex-shrink: 100;
}
._header_1ikyp_45 ._name_1ikyp_59 {
  text-align: center;
  margin: 3px 0;
  flex-grow: 10;
  flex-shrink: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media (max-width: 500px) {
  ._header_1ikyp_45 {
    align-items: flex-start;
    justify-content: flex-start;
  }
  ._header_1ikyp_45 ._name_1ikyp_59 {
    text-align: left !important;
    margin-left: 10px !important;
  }
  ._header_1ikyp_45 > div:first-child {
    display: none;
  }
}
@media (pointer: coarse) {
  ._galleryControl_1ikyp_27 li {
    margin: 0 7px;
  }
}

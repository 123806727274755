._secondary_1weam_1 {
  background: #ccc;
  border-color: #aaa;
}
._secondary_1weam_1:hover {
  background: #bdbdbd;
}
._secondary_1weam_1:active {
  background: #bbb;
}
._buttonBar_1weam_11 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 10px;
}
._buttonBar_1weam_11 button {
  margin-left: 10px;
}
._destructive_1weam_21 {
  background: #fd1818;
  border-color: #931111;
}
._destructive_1weam_21:hover {
  background: #e21515;
}
._destructive_1weam_21:active {
  background: #c01111;
}

._body_plwrm_1 {
  padding: 10px;
}
._init_plwrm_4 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 115px;
  flex-direction: column;
}
._selectSize_plwrm_11 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 115px;
  justify-content: space-between;
}
._progress_plwrm_18 {
  height: 95px;
  padding: 10px 0;
}
._progressBar_plwrm_22 {
  height: 25px;
  border: solid 1px var(--black);
  margin: 10px 0 10px;
  display: flex;
  justify-content: space-evenly;
}
._progressBar_plwrm_22 ._done_plwrm_29 {
  background: var(--main);
  flex-grow: 1;
}
._progressBar_plwrm_22 ._todo_plwrm_33 {
  background: #eee;
  flex-grow: 1;
}
._link_plwrm_37 {
  color: var(--main);
  margin-left: 5px;
  text-decoration: underline;
}
._link_plwrm_37:hover {
  color: var(--main-hover);
}
._text_plwrm_45 {
  margin-top: 10px;
}
._success_plwrm_48 {
  padding: 10px 0;
}
